import React from "react";
export default function ComingSoon() {
    return (
        <main>
            <section className="landing soon-landing">
                <div className="left-bottle"></div>
                <div className="bottom-bottle"></div>
                <div className="scroll"></div>
                <div className="soon-title-page">
                    <h1>Coming Soon</h1>
                    <h2>
                        Lorem ipsum dolor s
                        <span className="baskerville italic gold-text">
                            it amet consectetur adipisicing
                        </span>{" "}
                        elit. Tempore quos expedita{" "}
                        <span className="baskerville italic gold-text">
                            minima laborum. Iste, repellat accusantium
                        </span>{" "}
                        obcaecati eligendi ratione qui perspiciatis voluptas.
                        Molestias, neque debitis!
                    </h2>
                </div>
            </section>
            <section className="landing soonInfo">
                <div className="soon">
                    <div className="left">
                        <h3>Coming soon</h3>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit.{" "}
                            <span className="gold-text">
                                Perferendis atque soluta suscipit qui aliquam
                            </span>
                            illo exercitationem quasi est.
                        </p>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Atque eius debitis qui rem quaerat assumenda
                            tempora beatae maiores iure architecto inventore
                            optio repellat, minus quod excepturi vel enim? Lorem
                            ipsum dolor sit amet consectetur.
                        </p>
                    </div>
                    <div className="right">
                        <div className="card">
                            <h4 className="baskerville">Nom de la bouteille</h4>
                            <p>2001 - Domaines des Ducs</p>
                            <div className="bottle"></div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
