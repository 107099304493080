import React from "react";
export default function Loading() {
    return (
        <>
            <div className="landing">
                <div className="loader"></div>
            </div>
        </>
    );
}
