import React from "react";
import Adder from "../../components/buttons/Adder";

export default function ProductDetails({ bottle, length }) {
    console.log(bottle);
    // TODO Acheter button will get information from the Adder component
    return (
        <section className="landing">
            <div className="product-detail">
                <div className="image-container">
                    <div className="is-limited">
                        <p>
                            #{bottle.quantity}/{length}
                        </p>
                        <p>
                            {bottle.is_limited ? <>Limited Edition</> : <></>}
                        </p>
                    </div>
                    <img src={bottle.straightImage} alt="" />
                    <div className="img-description">
                        <p>{`${bottle.rhumerie} ${bottle.name}`}</p>
                        <p>
                            {bottle.year}
                            {bottle.domaine ? ` - ${bottle.domaine}` : ""}
                        </p>
                    </div>
                    <div className="details">
                        <p className="baskerville">PRICE: {bottle.price} $</p>{" "}
                        <p>Average APR: (%): {bottle.apr}%</p>
                        <p>{bottle.time_left} days left</p>
                    </div>
                </div>
                <div className="description">
                    <div className="breadcrumb">
                        <h5>Cave &gt; Description produit</h5>
                    </div>
                    <h2>{`${bottle.rhumerie} ${bottle.name} ${bottle.year} ${bottle.type} ${bottle.abv}`}</h2>
                    <div className="detail">
                        {bottle.description.map((detail) => (
                            <p>{detail}</p>
                        ))}
                    </div>
                    <Adder />
                    <button>Acheter sur le marché secondaire</button>
                </div>
            </div>
        </section>
    );
}
