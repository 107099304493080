import TestArt1 from "../assets/images/art/Test Art1.png";
import TestArtist from "../assets/images/art/Test Artist1.png";
import LaFlibusteImage from "../assets/images/bottles/La Flibuste.png";
import LaFlibusteImage1 from "../assets/images/bottles/La Flibuste1.png";
import LaFlibusteImage2 from "../assets/images/bottles/La Flibuste2.png";
import LaFlibusteImage3 from "../assets/images/bottles/La Flibuste3.png";
import LesFreres from "../assets/images/bottles/Les Freres de la Cote.png";
import TestRewardNFT2 from "../assets/images/reward/Test Reward NFT2.png";

export const testBottle1 = {
    id: 0,
    name: "La Flibuste",
    description: [
        `Every connoisseur of rum is familiar with La Favorite. Its origins trace back to the era of Napoleon, who, while savoring this West Indies rum, is said to have declared, "this liqueur will be my favorite"! And who could blame him?`,

        `Fast forward to today, La Favorite produces its rums from sugar cane harvested from its 60-hectare estate, along with contributions from local small-scale producers. Powering this operation is a steam engine from 1900, a nod to its rich history, which fuels the entire distillery. The fermentation process uses baker's yeast, lending the La Favorite rums their distinctive, mouth-watering aromas. The distillation takes place in two Creole columns, where the priceless rums are born.`,

        `This specific blend symbolizes the harmony between age-old tradition and the aspiration to differentiate from other Martinican distilleries. After undergoing the traditional process, the distilled juices are aged in oak barrels that were once home to Cognac, enhancing the flavors of pastry, cream, plum, and spices. `,
    ],
    year: 1992,
    type: "Rhum",
    abv: "40%",
    country: "Martinique",
    region: "Caribbean",
    rhumerie: "La Favorite",
    price: 200.0,
    apr: 20,
    time_left: 2,
    is_limited: true,
    number: 53,
    stock: 100,
    available: true,
    quantity: 1,
    image: LaFlibusteImage,
    straightImage: LaFlibusteImage1,
    rating: 4.5,
    reviews: [
        {
            id: 1,
            name: "John Doe",
            rating: 4.5,
            comment: "This is a great bottle of rum!",
        },
        {
            id: 2,
            name: "Jane Doe",
            rating: 4.5,
            comment: "This is a great bottle of rum!",
        },
        {
            id: 3,
            name: "John Doe",
            rating: 4.5,
            comment: "This is a great bottle of rum!",
        },
    ],
};

export const testBottle2 = {
    id: 1,
    name: "Les Frères de la Côte",
    description: [
        `Every connoisseur of rum is familiar with La Favorite. Its origins trace back to the era of Napoleon, who, while savoring this West Indies rum, is said to have declared, "this liqueur will be my favorite"! And who could blame him?`,

        `Fast forward to today, La Favorite produces its rums from sugar cane harvested from its 60-hectare estate, along with contributions from local small-scale producers. Powering this operation is a steam engine from 1900, a nod to its rich history, which fuels the entire distillery. The fermentation process uses baker's yeast, lending the La Favorite rums their distinctive, mouth-watering aromas. The distillation takes place in two Creole columns, where the priceless rums are born.`,

        `This specific blend symbolizes the harmony between age-old tradition and the aspiration to differentiate from other Martinican distilleries. After undergoing the traditional process, the distilled juices are aged in oak barrels that were once home to Cognac, enhancing the flavors of pastry, cream, plum, and spices. `,
    ],
    year: 2012,
    type: "Rhum",
    abv: "40%",
    country: "Martinique",
    region: "Caribbean",
    rhumerie: "La Favorite",
    price: 80.0,
    apr: 20,
    time_left: 2,
    is_limited: false,
    number: 43,
    stock: 100,
    available: true,
    quantity: 1,
    image: LesFreres,
    straightImage: LesFreres,
    rating: 4.2,
    reviews: [
        {
            id: 1,
            name: "John Doe",
            rating: 4.5,
            comment: "This is a great bottle of rum!",
        },
        {
            id: 2,
            name: "Jane Doe",
            rating: 4.5,
            comment: "This is a great bottle of rum!",
        },
        {
            id: 3,
            name: "John Doe",
            rating: 4.5,
            comment: "This is a great bottle of rum!",
        },
    ],
};

export const testArtist = {
    id: 0,
    name: "Pascal Boyart",
    pseudo: "Pboy",
    description: [
        `Painter and graffiti artist Niche: Tokenized urban fresco Known as Pboy, this Parisian crypto-artist is the first muralist to have created NFTs from his urban works, including his famous "Liberty Leading the People", divided into 100 unique tokens. He is also the first to have accepted donations in Bitcoin, with which he financed his grandiose modern version of the Sistine Chapel at the gates of Paris. This has been transposed into 404 NFTs. His work has been relayed by numerous international media: BBC, The New York Times, TF1... Pascal Boyart is strongly influenced by Bruegel, Goya, Pollock, and Seurat. Passionate about drawing since always, he grew up in the Chapelle district, where he made a name for himself as a graffiti artist, thanks to his figurative style and technical ease. `,
    ],
    image: TestArtist,
};

export const testRewardNFT = TestRewardNFT2;

export const testBottles = [testBottle1, testBottle2];

export const testExplanation = [LaFlibusteImage2, TestArt1, LaFlibusteImage3];
