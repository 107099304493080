import React from "react";
import TestArt from "../../assets/images/art/Test Art1.png";
import TestArtist from "../../assets/images/art/Test Artist1.png";
import TestRewardNFT from "../../assets/images/reward/Test Reward NFT1.png";
import TestReward from "../../assets/images/reward/Test Reward1.png";

export default function IntroCards() {
    //TODO Make the page responsive
    return (
        <section className="intro-cards">
            <div className="landing">
                <div className="grid">
                    <figure className="card">
                        <img src={TestArtist} alt="" />
                        <figcaption>
                            <h3>Discover a new Web3 artist</h3>
                        </figcaption>
                    </figure>
                    <figure className="card">
                        <figcaption>
                            <h3 className="top-caption">
                                Own valuable exclusive NFT
                            </h3>
                        </figcaption>
                        <img src={TestArt} alt="" />
                    </figure>
                </div>
            </div>
            <div className="landing">
                <div className="grid">
                    <figure className="card">
                        <img src={TestReward} alt="" />
                        <figcaption>
                            <h3>Reveal your bottle</h3>
                        </figcaption>
                    </figure>
                    <figure className="card">
                        <figcaption>
                            <h3 className="top-caption">Redeem &amp; Enjoy</h3>
                        </figcaption>
                        <img src={TestRewardNFT} alt="" />
                    </figure>
                </div>
            </div>
        </section>
    );
}
