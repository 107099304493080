import React from "react";
import { testBottle1 } from "../../data/Test";

export default function IntroSpirit() {
    return (
        <section className="landing">
            <div className="intro-spirit">
                <div className="left">
                    <figure className="card">
                        <figcaption>
                            <h4 className="baskerville">{testBottle1.name}</h4>
                            <p>{`${testBottle1.year} - ${testBottle1.rhumerie}`}</p>
                            <button>J'investis</button>
                        </figcaption>
                        <img src={testBottle1.image} alt="" />
                    </figure>
                </div>
                <div className="right">
                    <div>
                        <h2>Reveal & Redeem your poison: </h2>
                        <p>
                            And now, for the moment of truth! Peel back the
                            virtual curtain and uncover the spirit-ual companion
                            of your NFT. Will it be a rare elixir or a beloved
                            classic? Only one way to find out! Unleash your
                            inner pirate, redeem your bounty, and toast to good
                            fortune.
                        </p>
                        <h2>Build a collection or enjoy the taste</h2>
                        <p>
                            It's time to make some tough decisions.You could
                            become the envied curator of a rare spirits
                            collection or tantalize your taste buds with your
                            new acquisitions. Or why not both? After all,
                            variety is the spice of life!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
