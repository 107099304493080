import React from "react";
import { testExplanation } from "../../data/Test";

export default function Explanation() {
    return (
        <section className="landing">
            <div className="explanation">
                <h2>Buy one, get two</h2>
                <p>
                    In the world of NFTs, it's what's on the inside that counts!
                    Each of our NFTs holds a secret, a hidden treasure - a
                    bottle of fine spirit. It's like a digital message in a
                    bottle, only instead of a message, it's a delightful
                    libation waiting to be revealed. The real question is, what
                    will your NFT be hiding?"
                </p>
                <div className="grid">
                    <div className="left">
                        <p className="title">10%</p>
                        <figure className="card">
                            <img src={testExplanation[0]} alt="" />
                        </figure>
                    </div>
                    <figure className="middle">
                        <img src={testExplanation[1]} alt="" />
                        <figcaption>
                            <p>
                                With 1 chance in 10 to unveil a rare,
                                potentially high-value treasure, and 9 chances
                                to discover a delightful regular spirit, it's a
                                winning game every time. The odds are ever in
                                your favor!"
                            </p>
                        </figcaption>
                    </figure>
                    <div className="right">
                        <p className="title">90%</p>
                        <figure className="card">
                            <img src={testExplanation[2]} alt="" />
                        </figure>
                    </div>
                </div>
            </div>
        </section>
    );
}
