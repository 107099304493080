import MathImage from "../assets/images/team/math1.png";
import pierreImage from "../assets/images/team/pierre1.png";
import seanImage from "../assets/images/team/sean1.png";
import tehjulImage from "../assets/images/team/tehjul1.png";

const mathieu = {
    id: 0,
    name: "Math",
    role: "Founder & rare rhum lover (cirrhosis in progress)",
    website: null,
    facebook: null,
    instagram: null,
    linkedin: null,
    twitter: null,
    image: MathImage,
};
const tehjul = {
    id: 1,
    name: "TehJul",
    role: "Founder & W3 Developer",
    website: null,
    facebook: null,
    instagram: null,
    linkedin: null,
    twitter: null,
    image: tehjulImage,
};
const sean = {
    id: 2,
    name: "Math",
    role: "Partner & Developer",
    website: null,
    facebook: null,
    instagram: null,
    linkedin: null,
    twitter: null,
    image: seanImage,
};
const pierre = {
    id: 3,
    name: "Pierre",
    role: "Partner & spirit cellar owner",
    website: null,
    facebook: null,
    instagram: null,
    linkedin: null,
    twitter: null,
    image: pierreImage,
};

export const teamDetails = [mathieu, tehjul, sean, pierre];
