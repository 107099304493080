import React from "react";
export default function Error404() {
    return (
        <>
            <div className="landing error-landing">
                <div className="error-information">
                    <h1>Erreur 404.</h1>
                    <h2>
                        Lorem ipsum dolor s
                        <span className="baskerville italic gold-text">
                            it amet consectetur adipisicing
                        </span>{" "}
                        elit. Tempore quos expedita{" "}
                        <span className="baskerville italic gold-text">
                            minima laborum. Iste, repellat accusantium
                        </span>{" "}
                        obcaecati eligendi ratione qui perspiciatis voluptas.
                        Molestias, neque debitis!
                    </h2>
                </div>
            </div>
        </>
    );
}
