import React from "react";
import TestArt from "../../assets/images/art/Test Art2.png";

export default function IntroArtist() {
    return (
        <section className="landing">
            <div className="intro-artist">
                <div className="left">
                    <figure className="card">
                        <img src={TestArt} alt="" />
                    </figure>
                </div>
                <div className="right">
                    <div>
                        <h2>Discover a new artist every month</h2>
                        <p>
                            Say adieu to monotony and hello to mystery! Each
                            month we're adding a fresh splash of creativity to
                            your life with a brand-new artist. Who will it be?
                            Well, you'll just have to wait and see! It's like a
                            birthday surprise but without the awkward singing
                        </p>
                        <h2>Own valuable exclusive NFT:</h2>
                        <p>
                            Step into the digital elite. Here's your golden
                            ticket to owning a one-of-a-kind, valuable NFT. It's
                            not just a purchase, it's a status upgrade. And
                            remember, exclusivity isn't just a perk, it's a
                            lifestyle.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
