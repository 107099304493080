import React from "react";

export default function Adder() {
    // const [count, setCount] = useState(0);
    return (
        <div className="buttons">
            <div className="adder">
                <span>-</span> <span className="count">0</span> <span>+ </span>
            </div>
            <button className="optional">Acheter</button>
        </div>
    );
}
