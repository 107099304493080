import React from "react";
import { FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { teamDetails } from "../../data/TeamInfo";

export default function Team() {
    return (
        <section className="landing">
            <div className="teams">
                <h3>Team</h3>
                <div className="members">
                    {teamDetails.map((member) => {
                        // console.log(member);
                        return (
                            <div className="member" key={member.id}>
                                <img src={member.image} alt="" />
                                <h5>{member.name}</h5>
                                <p>{member.role}</p>
                                <div className="social-media">
                                    <p>
                                        <span className="label">Linkedin</span>
                                        <FaLinkedinIn className="icon" />
                                    </p>
                                    <p>
                                        <span className="label">Twitter</span>
                                        <FaTwitter className="icon" />
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}
