import React from "react";
import discord from "../../assets/images/logo/discord.png";
import instagram from "../../assets/images/logo/instagram.png";
import linkedIn from "../../assets/images/logo/linkedIn.png";
import twitter from "../../assets/images/logo/twitter.png";

export default function Socials() {
    return (
        <section className="landing">
            <div className="socials">
                <h3>Réseaux</h3>
                <div className="links">
                    <div className="linkedin">
                        <div className="image-container">
                            <img src={linkedIn} alt="" />
                        </div>
                        <h5>Linkedin</h5>
                    </div>
                    <div className="instagram">
                        <div className="image-container">
                            <img src={instagram} alt="" />
                        </div>
                        <h5>Instagram</h5>
                    </div>
                    <div className="discord">
                        <div className="image-container">
                            <img src={discord} alt="" />
                        </div>
                        <h5>Discord</h5>
                    </div>
                    <div className="twitter">
                        <div className="image-container">
                            <img src={twitter} alt="" />
                        </div>
                        <h5>Twitter</h5>
                    </div>
                </div>
            </div>
        </section>
    );
}
