import React from "react";
import { HiChevronDown } from "react-icons/hi2";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logofilled.png";

export default function MainNavbar() {
    return (
        <nav className="header-nav">
            <ul>
                <div className="nav-left">
                    <li>
                        <Link to="/">
                            <img src={logo} alt=""></img>
                        </Link>
                    </li>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/caves">Caves</Link>
                    </li>
                    <li>
                        <a href="/nfts">Freemint</a>
                    </li>
                    <li>
                        <Link to="/soon">Dashboard</Link>
                    </li>
                    <li>
                        <a href="/error">Errors</a>
                    </li>
                </div>

                <div className="nav-right">
                    <li className="white-text">
                        FR <HiChevronDown />
                    </li>

                    <div className="Connect">
                        <button id="connexion">Se connecter</button>
                    </div>
                </div>
            </ul>
        </nav>
    );
}
