import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from "../components/partials/Loading";
import MainLayout from "../layouts/MainLayout";
import Cave from "../pages/Cave";
import Cellar from "../pages/Cellar";
import ComingSoon from "../pages/ComingSoon";
import Error404 from "../pages/Error404";
import Home from "../pages/Home";
// import Loading from "../pages/Loading";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<MainLayout />}>
                    {/* <Loading /> */}
                    <Route index element={<Home />} />
                    <Route path="/soon" element={<ComingSoon />} />
                    <Route path="/caves" element={<Cave />} />
                    <Route path="/nfts" element={<Cellar />} />
                    <Route path="/loading" element={<Loading />} />
                    <Route path="*" element={<Error404 />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
