import React from "react";
import { testArtist, testBottles, testRewardNFT } from "../data/Test";
import ArtistDetails from "../features/cave/ArtistDetails";
import Mint from "../features/cave/Mint";
import ProductDetails from "../features/cave/ProductDetails";

const bottles = testBottles;
const artists = testArtist;
export default function Cave() {
    return (
        <main className="cave">
            {bottles.map((bottle) => (
                <ProductDetails
                    bottle={bottle}
                    key={bottle.id}
                    length={bottles.length}
                />
            ))}
            <ArtistDetails artist={artists} key={artists.id} />
            <Mint nft={testRewardNFT} />
        </main>
    );
}
