import React from "react";
import Explanation from "../features/home/Explanation";
import IntroArtist from "../features/home/IntroArtist";
import IntroCards from "../features/home/IntroCards";
import IntroSpirit from "../features/home/IntroSpirit";
import Landing from "../features/home/Landing";
import Socials from "../features/home/Socials";
import Team from "../features/home/Team";
export default function Home() {
    return (
        <main className="home">
            <Landing />
            <IntroCards />
            <IntroArtist />
            <IntroSpirit />
            <Explanation />
            <Team />
            <Socials />
        </main>
    );
}
