import React from "react";

export default function ProductDetails({ artist }) {
    // TODO Acheter button will get information from the Adder component
    return (
        <section className="landing">
            <div className="artist-detail">
                <div className="image-container">
                    <div className="name">
                        <p>{artist.pseudo}</p>
                    </div>
                    <img src={artist.image} alt="" />
                </div>
                <div className="description">
                    <div className="breadcrumb">
                        <h5>Cave &gt; Description artiste</h5>
                    </div>
                    <h2>{`${artist.name} - ${artist.pseudo}`}</h2>
                    <div className="detail">
                        {artist.description.map((detail) => (
                            <p>{detail}</p>
                        ))}
                    </div>
                    <div className="mint">
                        <button>Mint</button>
                    </div>
                    <button>Secondary market</button>
                </div>
            </div>
        </section>
    );
}
