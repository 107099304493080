import React from "react";

export default function Landing() {
    //TODO Make the page responsive + Change the bottle images
    return (
        <section className="landing home-landing">
            <div className="upside-down-bottle"></div>
            <div className="right-bottle"></div>

            <div className="scroll"></div>

            <div className="landing-text">
                <h1 className="landing-title">BACCHUS VAULT.</h1>
                <h2>Savor the art, taste the exceptional</h2>
                <h5>
                    Lorem{" "}
                    <span className="gold-text italic">
                        ipsum dolor sit amet,
                    </span>{" "}
                    consectetur adispising elit, sed do eiusmod tempor{" "}
                    <span className="gold-text italic">
                        incididunt ut labore et dolor{" "}
                    </span>
                    magna aliqua.
                </h5>
            </div>
        </section>
    );
}
