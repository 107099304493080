import { Outlet } from "react-router-dom";
import MainNavbar from "../components/navbar/MainNavbar";
// import MainSidebar from "../components/sidebar/MainSidebar";

export default function MainLayout() {
    return (
        <>
            <MainNavbar />
            {/* <MainSidebar /> */}
            <Outlet />
        </>
    );
}
