import React from "react";
import Adder from "../../components/buttons/Adder";

export default function Mint({ nft }) {
    console.log(nft);
    // TODO Acheter button will get information from the Adder component
    return (
        <section className="landing">
            <div className="mint">
                <img src={nft} alt="" />
                <button>Mint</button>
                <Adder />
            </div>
        </section>
    );
}
